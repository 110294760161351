<template>

      <iframe src="https://autofaucet.org/wm/nonatrib/4" width="400" height="400" style="border:0"></iframe>
      
      
      <div id="App">
        <h1>Vue hCaptcha Demo</h1>

        <div v-if="verified" id="verified">
            <h1>Successfully Verified!</h1>
            <p>token: {{ token }}</p>
            <p>eKey: {{ eKey }}</p>
        </div>

        <div v-if="expired" id="expired">
            <h1>Challenge expired!</h1>
        </div>

        <div v-if="error" id="error">
            <h1>Error:</h1>
            <p>{{ error }}</p>
        </div>

        <h3>Normal checkbox (default props)</h3>
        <vue-hcaptcha
            ref="invisibleHcaptcha"
            sitekey="7f054287-6fa7-4dce-89f5-724f4f317ed7"
            @verify="onVerify"
            @expired="onExpire"
            @challenge-expired="onChallengeExpire"
            @error="onError"
        />

        <button @click="onSubmit">
            Click Here!
        </button>
    </div>
  
</template>

<script setup>
  import { ref } from "vue";
  import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';
  
  const verified = ref(false);
  const expired = ref(false);
  const token = ref("");
  const eKey = ref("");
  const error = ref("");
  const invisibleHcaptcha = ref(null);
  function onVerify(tokenStr, ekey) {
      verified.value = true;
      token.value = tokenStr;
      eKey.value = ekey;
      console.log(`Callback token: ${tokenStr}, ekey: ${ekey}`);
  }
  function onExpire() {
      verified.value = false;
      token.value = null;
      eKey.value = null;
      expired.value = true;
      console.log('Expired');
  }
  function onChallengeExpire() {
      verified.value = false;
      token.value = null;
      eKey.value = null;
      expired.value = true;
      console.log('Challenge expired');
  }
  function onError(err) {
      token.value = null;
      eKey.value = null;
      error.value = err;
      console.log(`Error: ${err}`);
  }
  function onSubmit() {
      console.log('Submitting the invisible hCaptcha');
      invisibleHcaptcha.value.execute();
  }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
